import { connect } from 'react-redux';
import OfficialCrmIcons from '../../Components/OfficialCrmIcons';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { OFFICIAL_CRM_ICONS } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[OFFICIAL_CRM_ICONS],
  ...ownProps
});

export default connect(mapStateToProps, {})(withIframeHistory(OfficialCrmIcons, {
  name: OFFICIAL_CRM_ICONS,
  defaultUrl: Config.reactApp.OFFICIAL_CRM_ICONS_URL
}));
