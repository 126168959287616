import { connect } from 'react-redux';
import Presentations from '../../Components/Presentations';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { PRESENTATIONS } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[PRESENTATIONS],
  ...ownProps
});

export default connect(mapStateToProps, {})(withIframeHistory(Presentations, {
  name: PRESENTATIONS,
  defaultUrl: Config.reactApp.PRESENTATION_IFRAME_URL
}));
