import * as screens from '../../constants/screens';
/**
 *
 * @param {*} ability function get from AbilityContext
 * @param {*} t translation function
 */
export const buildCrmResourcesMenuItems = (ability, t) => {
  const menuItems = [];

  if (!ability) {
    return [];
  }

  if (ability.can('view', screens.TRAINING_MATERIALS)) {
    menuItems.push({
      container: 'Training Materials',
      icon: undefined,
      title: t('sideMenu:trainingMaterials'),
      path: '/training-materials',
    });
  }

  if (ability.can('view', screens.OFFICIAL_CRM_ICONS)) {
    menuItems.push({
      container: 'Official CRM Icons',
      icon: undefined,
      title: t('sideMenu:officialCrmIcons'),
      path: '/official-crm-icons',
    });
  }

  if (ability.can('view', screens.PRESENTATIONS)) {
    menuItems.push({
      container: 'Presentations',
      icon: undefined,
      title: t('sideMenu:presentations'),
      path: '/presentations',
    });
  }

  return menuItems;
};
