import { connect } from 'react-redux';
import TrainingMaterials from '../../Components/TrainingMaterials';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { TRAINING_MATERIALS } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[TRAINING_MATERIALS],
  ...ownProps
});

export default connect(mapStateToProps, {})(withIframeHistory(TrainingMaterials, {
  name: TRAINING_MATERIALS,
  defaultUrl: Config.reactApp.TRAINING_MATERIALS_URL
}));
