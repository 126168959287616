import Config from '../Config';
import * as screens from '../constants/screens';

const IFRAME_HISTORY_CHANGE_URL = 'IFRAME_HISTORY_CHANGE_URL';
const IFRAME_HISTORY_CURRENT_SCREEN = 'IFRAME_HISTORY_CURRENT_SCREEN';

export function iframeHistoryDefaultState() {
  return {
    currentScreen: null,
    [screens.TRAINING_MATERIALS]: Config.reactApp.TRAINING_MATERIALS_URL,
    [screens.OFFICIAL_CRM_ICONS]: Config.reactApp.OFFICIAL_CRM_ICONS_URL,
    [screens.PRESENTATIONS]: Config.reactApp.PRESENTATION_IFRAME_URL,
    [screens.ONBOARDING]: Config.reactApp.ONBOARDING_URL,
  };
}

export function setCurrentScreen(dispatch, payload) {
  dispatch({
    type: IFRAME_HISTORY_CURRENT_SCREEN,
    payload
  });
}

export function setIframeHistoryUrl(dispatch, { name, url }) {
  dispatch({
    type: IFRAME_HISTORY_CHANGE_URL,
    payload: { name, url }
  });
}

export default (state = iframeHistoryDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case IFRAME_HISTORY_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: payload
      };

    case IFRAME_HISTORY_CHANGE_URL:
      return {
        ...state,
        [payload.name]: payload.url
      };

    default:
      return state;
  }
};
