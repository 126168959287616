import { connect } from 'react-redux';
import Onboarding from '../../Components/Onboarding';
// import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
// import { ONBOARDING } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: Config.reactApp.ONBOARDING_URL,
  ...ownProps
});

export default connect(mapStateToProps, {})(Onboarding);
