import { connect } from 'react-redux';
import { ResponsiveLayout } from 'componentlibrary';
import { logout } from '../../reducers/auth';
import { getcurrentlanguage, languageOptions } from '../../translations/languageOptions';
import config from '../../Config';
import { localeMapper } from '../../utils/LocaleData';

const mapStateToProps = (state, ownProps) => ({
  environmentName: config.reactApp.ENVIRONMENT_NAME,
  userEmail: state.auth.idToken.email,
  username: state.auth.idToken.preferred_username,
  jwtToken: state.auth.idTokenJwtString,
  forwoodIdUrl: config.forwoodId.URL,
  forwoodIdGateway: config.forwoodId.APIGATEWAY,
  cognitoUsername: state.auth.idToken['cognito:username'],
  appType: 'resources',
  useRedirectTrick: true,
  getCurrentLanguage: getcurrentlanguage,
  localeMapper,
  languageOptions,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  logout(...args) {
    return logout(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveLayout);
