import React, {
  useState,
  useMemo,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import Url from 'url-parse';
import Can from '../Can';
import { ONBOARDING } from '../../constants/screens';
import styles from './styles.module.scss';

export const getParsedUrl = (currentUrl, currentLanguage) => {
  const iframeUrl = new Url(currentUrl);
  const urlParams = qs.parse(iframeUrl.query, { ignoreQueryPrefix: true });
  delete urlParams.timestamp;
  urlParams.lang = currentLanguage;
  const stringifyParams = qs.stringify(urlParams);
  const url = `${iframeUrl.protocol}//${iframeUrl.hostname}${iframeUrl.pathname}?${stringifyParams}`;

  return url;
};

export default function OnboardingComponent({
  currentUrl, currentLanguage
}) {
  const [iframeLoading, setIframeLoading] = useState(true);

  useEffect(() => {
    setIframeLoading(true);
  }, [currentUrl]);

  const parsedIframeUrl = useMemo(() => getParsedUrl(currentUrl, currentLanguage), [currentLanguage]);

  return (
    <Can I="view" a={ONBOARDING}>
      <iframe
        title="external-html-content"
        src={parsedIframeUrl}
        style={{
          display: iframeLoading ? 'none' : 'block',
          height: '100%',
          width: '100%',
        }}
        onLoad={() => {
          setIframeLoading(false);
        }}
        className={styles.borderless}
        frameBorder={0}
        width="100%"
        height="100%"
        allow="geolocation *"
      />
    </Can>
  );
}

OnboardingComponent.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
};
