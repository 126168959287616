import { Ability, AbilityBuilder } from '@casl/ability';
import * as screens from '../../constants/screens';

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  // this one is required
  // eslint-disable-next-line no-underscore-dangle
  return item.__type;
}

const ability = new Ability([], { subjectName });

export function updateAbility(currentAbility) {
  const { can, rules } = new AbilityBuilder();
  can('view', 'HomePage');

  can('view', 'CRMResourcesMenu');
  can('view', screens.TRAINING_MATERIALS);
  can('view', screens.OFFICIAL_CRM_ICONS);
  can('view', screens.PRESENTATIONS);
  can('view', screens.ONBOARDING);

  currentAbility.update(rules);
}

export default ability;
