import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

// import { Redirect } from 'react-router';
// import Home from './Components/Home';
import TrainingMaterials from './Containers/TrainingMaterials';
import OfficialCrmIcons from './Containers/OfficialCrmIcons';
import Presentations from './Containers/Presentations';
import Onboarding from './Containers/Onboarding';

/*
  For documentation on deeply nested routes refer here:
  https://github.com/reacttraining/react-router/tree/master/packages/react-router-config
*/
const Root = ({ route }) => (
  <>
    {/* child routes won't render without this */}
    {renderRoutes(route.routes)}
  </>
);

const IDHomepage = () => {
  const getDomainWithoutFirstSubdomain = () => {
    const domain = window.location.hostname;
    const domainParts = domain.split('.');
    return domainParts.slice(1).join('.');
  };

  window.location.href = `https://id.${getDomainWithoutFirstSubdomain()}`;
};

export const routesV1 = [
  {
    component: Root,
    routes: [
      {
        path: "/",
        exact: true,
        component: TrainingMaterials,
      },
      {
        path: "/training-materials",
        exact: true,
        component: TrainingMaterials,
      },
      {
        path: "/official-crm-icons",
        exact: true,
        component: OfficialCrmIcons,
      },
      {
        path: "/presentations",
        exact: true,
        component: Presentations,
      },
      {
        path: "/id-homepage",
        exact: true,
        component: IDHomepage,
      },
    ],
  },
];

export const routesV2 = [{
  component: Root,
  routes: [
    {
      path: '/',
      exact: true,
      component: Onboarding
    },
    {
      path: '/training-materials',
      exact: true,
      component: TrainingMaterials,
    },
    {
      path: '/official-crm-icons',
      exact: true,
      component: OfficialCrmIcons,
    },
    {
      path: '/presentations',
      exact: true,
      component: Presentations,
    },
    {
      path: '/id-homepage',
      exact: true,
      component: IDHomepage,
    },
    {
      path: '/onboarding',
      exact: true,
      component: Onboarding,
    }
  ]
}];

Root.propTypes = {
  route: PropTypes.object.isRequired
};
